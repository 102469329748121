<template>
  <b-container fluid class="content">
    <b-alert
      :show="countdown"
      dismissible
      :variant="cd_variant"
      @dismissed="countdown = 0"
      @dismiss-count-down="close_alert"
      class="show_alert"
    >
      {{ cd_message }}
    </b-alert>
    <b-card>
      <el-row :gutter="10" class="align-center">
        <el-col :span="6" :offset="12">
          <el-button
            type="primary"
            @click="sort_order"
            :disabled="editing_idn >= 0"
            v-acl:UnitManageEdit.enable.show
          >{{
            isSortableEnabled ? $t("Save Change") : $t("Sort Order")
          }}</el-button>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="add_unit"
            :disabled="isSortableEnabled"
            v-acl:UnitManageEdit.enable.show
          >{{
            $t("Add")
          }}</el-button>
        </el-col>
      </el-row>
    </b-card>
    <b-card>
      <el-table
        stripe
        :class="{handshape:isSortableEnabled}"
        class="data-grid table-responsive table"
        header-row-class-name="thead-light"
        :data="unit_list"
        style="width: 100%; padding: 0;"
        ref="dragTable"
        row-key="IDn"
        @row-click="row_click"
      >
        <el-table-column width="50" column-key="Status">
          <template
            v-slot="{row, $index}"
          >
            <el-checkbox
              class="grid-checkbox"
              v-if="row.IDn == editing_idn"
              v-model="cur_unit.Status"
              :true-label="1"
              :false-label="0"
              @change="unit_active_edit"
              :disabled="isSortableEnabled"
              v-acl:UnitManageEdit.enable
            >
            </el-checkbox>
            <el-checkbox
              class="grid-checkbox"
              v-else
              v-model="row.Status"
              :true-label="1"
              :false-label="0"
              @change="unit_active_update($index)"
              :disabled="isSortableEnabled"
              v-acl:UnitManageEdit.enable
            >
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          prop="Unit"
          :label="$t('Unit')"
          width="200"
        >
          <template v-slot="{row}">
            <b-input
              class="form-control"
              v-model="cur_unit.Unit"
              v-if="row.IDn == editing_idn"
            />
            <div v-else>{{ row.Unit }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Type"
          :label="$t('Type')"
          width="200"
        >
          <template v-slot="{row}">
            <select
              class="form-control"
              v-if="row.IDn == editing_idn"
              v-model="cur_unit.Type"
              @change="type_change"
            >
              <option value="Count">{{ "Count" }}</option>
              <option value="Weight">{{ "Weight" }}</option>
              <option value="Length">{{ "Length" }}</option>
              <option value="Area">{{ "Area" }}</option>
              <option value="Volumn">{{ "Volumn" }}</option>
            </select>
            <div v-else>{{ row.Type }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Rate"
          :label="$t('Rate_1')"
          width="200"
        >
          <template v-slot="{row}">
            <b-input
              class="form-control"
              v-model="cur_unit.Rate"
              v-if="row.IDn == editing_idn && cur_unit.Type.toLowerCase() != 'count'"
              @change="rate_change"
            />
            <div v-else-if="row.Type.toLowerCase() != 'count'">{{ parseFloat(row.Rate).toFixed(4) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="OppositeRate"
          :label="$t('Opposite Rate')"
          width="200"
          v-if="false"
        >
          <template v-slot="{row}">
            <b-input
              class="form-control"
              v-model="cur_unit.OppositeRate"
              v-if="row.IDn == editing_idn && cur_unit.Type.toLowerCase() != 'count'"
            />
            <div v-else-if="row.Type.toLowerCase() != 'count'">{{ parseFloat(row.OppositeRate).toFixed(4) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Remarks"
          :label="$t('Remarks')"
        >
          <template v-slot="{row}">
            <b-input
              class="form-control"
              v-model="cur_unit.Remarks"
              v-if="row.IDn == editing_idn"
            />
            <div v-else>{{ row.Remarks }}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="130"
          column-key="Buttons"
          v-if="hasUtilityAuth('UnitManageEdit')"
        >
          <template v-slot="{row, $index}">
            <el-tooltip content="Edit" placement="top" v-if="row.IDn != editing_idn">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="edit_unit($index, row)"
                :disabled="isSortableEnabled"
                v-acl:UnitManageEdit.enable.show
              >
              </el-button>
            </el-tooltip>
            <el-tooltip content="Remove" placement="top" v-if="row.IDn != editing_idn">
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="remove_unit($index, row)"
                :disabled="isSortableEnabled"
                v-acl:UnitManageEdit.enable.show
              >
              </el-button>
            </el-tooltip>
            <el-tooltip content="Confirm" placement="top" v-if="row.IDn == editing_idn">
              <el-button
                type="primary"
                icon="el-icon-check"
                circle
                @click="confirm_unit($index, row)"
                :disabled="isSortableEnabled"
                v-acl:UnitManageEdit.enable.show
              >
              </el-button>
            </el-tooltip>
            <el-tooltip content="Cancel" placement="top" v-if="row.IDn == editing_idn">
              <el-button
                type="danger"
                icon="el-icon-close"
                circle
                @click="cancel_unit($index, row)"
                :disabled="isSortableEnabled"
                v-acl:UnitManageEdit.enable.show
              >
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </b-card>
    
  </b-container>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Cascader,
  Tabs,
  TabPane,
  Container,
  Main,
  Footer,
  Switch,
  Checkbox,
  Tooltip,
} from "element-ui";
import { getStore, setStore } from "../../util/helper";
import Sortable from "sortablejs";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Cascader.name]: Cascader,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Container.name]: Container,
    [Main.name]: Main,
    [Footer.name]: Footer,
    [Switch.name]: Switch,
    [Checkbox.name]: Checkbox,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      token: "",
      user_id: 0,
      countdown: 0,
      cd_message: "",
      cd_variant: "info",

      sortable: null,
      isSortableEnabled: false,

      editing_idn: -1,
      unit_list: [],
      unit_list_display: [],
      new_unit: {
        IDn: 0,
        Unit: "",
        UnitName: "",
        Type: "Count",
        Rate: "1.00000000",
        OppositeRate: "1.00000000",
        Remarks: '',
        seq: 999,
        Status: 1,
      },
      cur_unit: {},
    };
  },
  watch: {
    unit_list: function () {
      this.get_unit_list_display();
    },
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    hasUtilityAuth,
    type_change(value) {
      let tmp = {};
      for (const p in this.cur_unit) {
        tmp[p] = this.cur_unit[p];
      }
      this.cur_unit = tmp;
    },
    rate_change(value) {
      let tmp = {};
      for (const p in this.cur_unit) {
        if (p == "OppositeRate") tmp[p] = (1 / parseFloat(value)).toFixed(8).toString();
        else tmp[p] = this.cur_unit[p];
      }
      this.cur_unit = tmp;
    },
    row_click(row, column, event) {
      if (!hasUtilityAuth('UnitManageEdit')) return;
      let i;
      for (i = 0; i < this.unit_list.length; i++) {
        if (this.unit_list[i] === row) break;
      }
      if (
        i == this.unit_list.length || this.editing_idn == row.IDn ||
        (column.columnKey &&
          (column.columnKey === "Status" || column.columnKey === "Buttons")
        )
      ) return;
      this.edit_unit(i, row);
    },
    get_unit_list_display() {
      // if sorting is started, the unit_list_display
      // would not be updated along with unit_list anymore.
      // After save new sort order, unit_list should
      // be reloaded and the page should be refreshed
      // and let unit_list_display gets updated again.
      if (!this.isSortableEnabled) {
        var ret = [];
        if (this.unit_list) {
          for (var i = 0; i < this.unit_list.length; i++){
            ret.push(this.unit_list[i]);
          }
        }
        this.unit_list_display = ret;
      }
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_unit_list();
    },
    sort_order() {
      if (this.isSortableEnabled) {
        this.disableSort();
        // save new seq

      } else {
        this.enableSort();
      }
    },
    add_unit() {
      if (this.editing_idn >= 0) this.cancel_unit();
      this.editing_idn = 0;
      for (const p in this.new_unit) {
        this.cur_unit[p] = this.new_unit[p];
      }
      this.unit_list.push(this.cur_unit);
    },
    edit_unit(index, unit) {
      if (this.editing_idn >= 0) this.cancel_unit();
      this.editing_idn = unit.IDn;
      for (const p in unit) {
        this.cur_unit[p] = unit[p];
      }
    },
    remove_unit(index, unit) {
      this.remove(index);
    },
    confirm_unit(index, unit) {
      this.edit();
    },
    cancel_unit(index, unit) {
      if (this.cur_unit["IDn"] == 0) {
        for (var i = 0; i < this.unit_list.length; i++) {
          if (this.unit_list[i]["IDn"] == 0) {
            this.unit_list.splice(i, 1);
          }
        }
      }
      this.editing_idn = -1;
      this.cur_unit = [];
    },
    unit_active_edit(value) {
      var tmp_unit = {};
      for (const p in this.cur_unit) {
        if (p == "Status") tmp_unit[p] = (value ? 1 : 0);
        else tmp_unit[p] = this.cur_unit[p];
      }
      this.cur_unit = tmp_unit;
    },
    unit_active_update(index) {
      this.edit(index);
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    disableSort() {
      this.update_sort(false, (that) => {
        that.sortable.option('disabled', true);
        delete that.sortable;
        that.isSortableEnabled = false;
      });
    },
    enableSort() {
      if (this.unit_list.length <= 0) {
        return;
      }
      this.setSort();
      this.isSortableEnabled = true;
    },
    setSort() {      
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        sort: true,
        disabled: false,
        animation: 150,
        ghostClass: 'sortable-ghost',
        onEnd: (evt) => {
          // console.log("onEnd");
          const targetRow = this.unit_list.splice(evt.oldIndex, 1)[0];
          this.unit_list.splice(evt.newIndex, 0, targetRow);
          for(var i = 0; i < this.unit_list.length; i++) {
            this.unit_list[i]["seq"] = i + 1;
          }
        },
      });
    },
    async get_unit_list() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/UnitManage",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.unit_list = rt.data.unit_list;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async edit(index = null) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (index != null && index >= 0) {
        for (const p in this.unit_list[index]) {
          bodyFormData.append(p, this.unit_list[index][p]);
        }
      }
      else if (this.cur_unit && this.cur_unit["Unit"]) {
        for (const p in this.cur_unit) {
          bodyFormData.append(p, this.cur_unit[p]);
        }
      }
      else {
        this.cd_message = "System error(Unit not found).";
        this.cd_variant = "warning";
        this.countdown = 3;
        return;
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/UnitManage/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.unit_list = rt.data.unit_list;
              that.editing_idn = -1;
              that.cur_unit = [];
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              // alert(rt.message);
              that.cd_message = rt.message;
              that.cd_variant = "warning";
              that.countdown = 3;
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          // that.$router.push("/login");
          that.cd_message = error;
          that.cd_variant = "warning";
          that.countdown = 3;
        });
    },
    async remove(index) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.unit_list[index]["IDn"]) bodyFormData.append("IDn", this.unit_list[index]["IDn"]);
      else {
        this.unit_list.splice(index, 1);
        return;
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/UnitManage/remove",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.unit_list = rt.data.unit_list;
              that.editing_idn = -1;
              that.cur_unit = [];
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              // alert(rt.message);
              that.cd_message = rt.message;
              that.cd_variant = "warning";
              that.countdown = 3;
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          // that.$router.push("/login");
          that.cd_message = error;
          that.cd_variant = "warning";
          that.countdown = 3;
        });
    },
    async update_sort(autoRefresh = true, disableSortablCallback = () => {}) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.unit_list && this.unit_list.length > 0) {
        bodyFormData.append("unit_list", JSON.stringify(this.unit_list));
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/UnitManage/update_sort",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              disableSortablCallback(that);
              that.unit_list = rt.data.unit_list;
              if (autoRefresh) that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.align-center {
  text-align: center;
}
.grid-row {
  margin-top: 10px;
  margin-bottom: 10px;
}
.grid-row div {
  min-height: 1px;
  margin-top: 0.5rem;
  text-align: center;
}
.grid-checkbox {
  text-align: center;
  padding-top: 10px;
}
.grid-checkbox .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.grid-checkbox .el-checkbox__inner::after {
  height: 12px;
  left: 7px;
}
.handshape{
  cursor: pointer;
}
.data-grid .el-table__body-wrapper .el-table__body .el-table__row td {
  padding-left: 0;
  padding-right: 0;
}
.data-grid .el-table__fixed-body-wrapper .el-table__body .el-table__row td {
  padding-left: 0;
  padding-right: 0;
}
.data-grid .el-table__body-wrapper .el-table__body .el-table__row .cell {
  text-align: center;
}
.data-grid .el-table__fixed-body-wrapper .el-table__body .el-table__row .cell {
  text-align: center;
}
</style>